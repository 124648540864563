import request from "./request";
//首页
//轮播
export async function getSliderList(params) {
    return request.post('/user/basic/getSliderList', params);
}
//公告列表
export async function getNoticeList(params) {
  return request.post('/user/basic/getNoticeList', params);
}
//公告详情
export async function getNoticeData(params) {
  return request.post('/user/basic/getNoticeData', params);
}
//获取商品列表
//传值：page=1, limit=15, zone_id, goods_type_id
export async function getGoodsList(params) {
  return request.post('/user/basic/getGoodsList', params);
}
//商品详情
export async function getGoodsData(params) {
  return request.post('/user/basic/getGoodsData', params);
}
//获取商品评价列表
export async function getGoodsCommentList(params) {
  return request.post('/user/basic/getGoodsCommentList', params);
}

//商品分类
//传值：page=1, limit=15, zone_id
export async function getGoodsTypeList(params) {
  return request.post('/user/basic/getGoodsTypeList', params);
}

//登录
export async function onLoginByAccountForPc(params) {
  return request.post('/user/login/onLoginByAccountForPc', params);
}
//注册
export async function onRegisterForPc(params) {
  return request.post('/user/login/onRegisterForPc', params);
}
//获取用户详情
export async function getUserData(params) {
  return request.post('/user/user/getUserData', params);
}
//发送短信验证码短信
export async function sendCodeSms(params) {
  return request.post('/user/basic/sendCodeSms', params);
}
//获取省份列表
export async function getProvinceList(params) {
  return request.post('/user/basic/getProvinceList', params);
}
//获取城市列表
export async function getCityList(params) {
  return request.post('/user/basic/getCityList', params);
}
//获取区县列表
export async function getCountyList(params) {
  return request.post('/user/basic/getCountyList', params);
}
//推荐注册新用户
export async function insertUserData(params) {
  return request.post('/user/user/insertUserData', params);
}
//修改用户登录密码
export async function updateUserLoginPasswordData(params) {
  return request.post('/user/user/updateUserLoginPasswordData', params);
}
//修改用户支付密码
export async function updateUserPayPasswordData(params) {
  return request.post('/user/user/updateUserPayPasswordData', params);
}
//获取用户收货地址列表
export async function getUserAddressList(params) {
  return request.post('/user/user/getUserAddressList', params);
}
//新增/修改用户收货地址
export async function setUserAddressData(params) {
  return request.post('/user/user/setUserAddressData', params);
}
//修改用户收货地址是否默认
export async function updateUserAddressIsdefaultData(params) {
  return request.post('/user/user/updateUserAddressIsdefaultData', params);
}
//修改用户收货地址状态
export async function updateUserAddressStatusData(params) {
  return request.post('/user/user/updateUserAddressStatusData', params);
}
//获取备选用户简要信息列表
export async function getUserBriefList(params) {
  return request.post('/user/user/getUserBriefList', params);
}
//修改用户头像
export async function updateUserAvatarData(params) {
  return request.post('/user/user/updateUserAvatarData', params);
}
//获取本人推荐待激活账号列表
export async function getUserRecUnactivatedList(params) {
  return request.post('/user/user/getUserRecUnactivatedList', params);
}
//获取本人推荐已激活账号列表
export async function getUserRecActivatedList(params) {
  return request.post('/user/user/getUserRecActivatedList', params);
}
//获取本人推荐统计数据
export async function getUserRecStatisticsData(params) {
  return request.post('/user/user/getUserRecStatisticsData', params);
}
//获取我的收藏列表
export async function getGoodsCollectList(params) {
  return request.post('/user/user/getGoodsCollectList', params);
}
//新增收藏
export async function insertGoodsCollectData(params) {
  return request.post('/user/user/insertGoodsCollectData', params);
}
//取消收藏（可批量）
export async function deleteGoodsCollectList(params) {
  return request.post('/user/user/deleteGoodsCollectList', params);
}
//发送重置密码短信验证码
export async function sendResetPasswordCodeSms(params) {
  return request.post('/user/basic/sendResetPasswordCodeSms', params);
}
//通过短信验证码重置登录密码
export async function updateUserLoginPasswordDataByCode(params) {
  return request.post('/user/basic/updateUserLoginPasswordDataByCode', params);
}
//通过短信验证码重置支付密码
export async function updateUserPayPasswordDataByCode(params) {
  return request.post('/user/basic/updateUserPayPasswordDataByCode', params);
}





//获取订单列表
export async function getOrderList(params) {
  return request.post('/user/order/getOrderList', params);
}
//获取订单详情
export async function getOrderData(params) {
  return request.post('/user/order/getOrderData', params);
}
//获取售后退款列表
export async function getOrderRefundList(params) {
  return request.post('/user/order/getOrderRefundList', params);
}
//下单
export async function insertOrderData(params) {
  return request.post('/user/order/insertOrderData', params);
}
//订单支付
export async function updateOrderPayData(params) {
  return request.post('/user/order/updateOrderPayData', params);
}
//取消订单
export async function updateOrderCancelData(params) {
  return request.post('/user/order/updateOrderCancelData', params);
}
//获取售后退款详情
export async function getOrderRefundData(params) {
  return request.post('/user/order/getOrderRefundData', params);
}
//新增售后退款申请
export async function insertOrderRefundData(params) {
  return request.post('/user/order/insertOrderRefundData', params);
}
//获取售后退款试算手续费
export async function getOrderRefundFeeData(params) {
  return request.post('/user/order/getOrderRefundFeeData', params);
}
//手动确认收货
export async function updateOrderFinishData(params) {
  return request.post('/user/order/updateOrderFinishData', params);
}
//获取我的页订单徽标数
export async function getOrderBadgeNumData(params) {
  return request.post('/user/order/getOrderBadgeNumData', params);
}
//新增评价
export async function insertOrderCommentData(params) {
  return request.post('/user/order/insertOrderCommentData', params);
}
//修改未支付订单使用积分
export async function updateOrderScoreJlData(params) {
  return request.post('/user/order/updateOrderScoreJlData', params);
}
//补充售后回寄快递信息
export async function updateOrderRefundExpressData(params) {
  return request.post('/user/order/updateOrderRefundExpressData', params);
}
//获取下单运费
export async function getOrderFreightData(params) {
  return request.post('/user/order/getOrderFreightData', params);
}




//获取积分类型列表
export async function getScoreTypeList(params) {
  return request.post('/user/score/getScoreTypeList', params);
}
//获取可转赠积分类型列表
export async function getScoreTypeDonateList(params) {
  return request.post('/user/score/getScoreTypeDonateList', params);
}
//获取积分列表
export async function getScoreList(params) {
  return request.post('/user/score/getScoreList', params);
}
//积分转赠
export async function updateUserScoreDonateData(params) {
  return request.post('/user/score/updateUserScoreDonateData', params);
}
//积分转移
export async function updateUserScoreTransferData(params) {
  return request.post('/user/score/updateUserScoreTransferData', params);
}
//获取种子类型列表
export async function getSeedTypeList(params) {
  return request.post('/user/score/getSeedTypeList', params);
}
//获取种子统计数据
export async function getSeedStatisticsData(params) {
  return request.post('/user/score/getSeedStatisticsData', params);
}
//获取种子列表
export async function getSeedList(params) {
  return request.post('/user/score/getSeedList', params);
}



//购物车
//获取购物车商品列表
export async function getCartList(params) {
  return request.post('/user/cart/getCartList', params);
}
//获取购物车商品详情
export async function getCartData(params) {
  return request.post('/user/cart/getCartData', params);
}
//加入购物车
export async function insertCartData(params) {
  return request.post('/user/cart/insertCartData', params);
}
//修改购物车商品数量
export async function updateCartNumData(params) {
  return request.post('/user/cart/updateCartNumData', params);
}
//批量删除购物车商品
export async function deleteCartStatusList(params) {
  return request.post('/user/cart/deleteCartStatusList', params);
}


