<template>
<router-view/>
</template>
<script >
document.body.style.backgroundColor='rgba(234, 232, 235, 1)'
</script>
<style lang="less">
@import "@/utils/replace.css";
*{margin: 0;
padding: 0;}
</style>
