import { createRouter, createWebHashHistory } from 'vue-router'
import store from '../store'
import { notification } from "ant-design-vue";
const routes = [
  {
    path: '/',
    component: () => import('../views/layout.vue'),
    children: [
      {
        path: '/index',
        name: 'index',
        component: () => import('../views/index'),
      },
      {
        path: '/my',
        name: 'my',
        component: () => import('../views/my'),
      },
      {
        path: '/goodslist',
        name: 'goodslist',
        component: () => import('../views/goodslist')
      },
      {
        path: '/goodsdetail',
        name: 'goodsdetail',
        component: () => import('@/views/goodsdetail')
      },
      {
        path: '/morenotice',
        name: 'morenotice',
        component: () => import('@/views/morenotice'),
      },
      {
        path: '/noticedetail',
        name: 'noticedetail',
        component: () => import('@/views/noticedetail')
      },
      {
        path: '/car',
        component: () => import('@/views/car.vue')
      },
      {
        path: '/ordercomment',
        name: 'ordercomment',
        component: () => import('@/views/ordercomment.vue'),
        beforeEnter: (to, from, next) => {
          if (from.path == '/' || from.path == '/commentdetail') {
            next()
          } else {
            next({ path: '/my', query: { show: 1 } })
          }
        }
      }
      ,
      {
        path: '/orderdetail',
        name: 'orderdetail',
        component: () => import('@/views/orderdetail.vue')
      }
      ,
      {
        path: '/commentdetail',
        name: 'commentdetail',
        component: () => import('@/views/commentdetail.vue'),
        // beforeEnter: (to, from, next) => {
        //   if (from.path == '/commentdetail') {
        //     next()
        //   } else {
        //     console.log(from);
        //     next({ path: '/my', query: { show: 1 } })
        //   }
        // }
      }
      ,
      {
        path: '/comtran',
        name: 'comtran',
        component: () => import('@/views/comtran.vue')
      },
      // { //审核流程暂无用
      //   path: '/orderrefound',
      //   name: 'orderrefound',
      //   component: () => import('@/views/orderrefound.vue'),
      //   // beforeEnter: (to, from, next) => {
      //   //   if (from.path == '/') {
      //   //     next()
      //   //   } else {
      //   //     console.log(from);
      //   //     next('/my')
      //   //   }
      //   // }
      // },
      {
        path: '/subfund',
        name: 'subfund',
        component: () => import('@/views/subfund.vue'),
        beforeEnter: (to, from, next) => {
          if (from.path == '/') {
            next()
          } else {
            next('/my')
          }
        }
      },
      {
        path: '/refunddetail',
        component: () => import('@/views/refunddetail.vue')
      },
      
      {
        path: '/allcomment',
        name: 'allcomment',
        component: () => import('@/views/allcomment.vue'),

      },
      {
        path: '/paydetail',
        name: 'paydetail',
        component: () => import('@/views/paydetail.vue'),

      },
      {
        path: '/cartprice',
        name: 'cartprice',
        component: () => import('@/views/cartprice.vue'),

      }
    ],
    redirect: '/index'
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/register.vue')
  },
  {
    path: '/rec_register',
    name: 'rec_register',
    component: () => import('@/views/rec_register.vue')
  },
  {
    path: '/forgotpass',
    name: 'forgotpass',
    component: () => import('@/views/forgotpass.vue')
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.VUE_APP_PUBLIC_PATH),
  routes
})
const needlogin = [ '/my','/car']
router.beforeEach((to, from, next) => {
  if (needlogin.includes(to.path)) {
    if (store.state.access_token) {
      next()
    } else {
      notification.info({message:'登录状态过期，请您重新登录'})
      next('/login')
    }
  } else {
    next()
  }

})
export default router
