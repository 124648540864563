import axios from "axios";
import router from '../router'
import store from '../store'
import {  notification } from "ant-design-vue";
const request = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL
  });
request.interceptors.response.use((config)=>{
  if(config.data.code==401){
    // notification.info({message:config.data.msg})
    router.push('/index')
    store.commit('clear')
  }
 return config.data
},
(err=>{
  return Promise.reject(err)
}))
request.interceptors.request.use((config)=>{
  if(store.state.access_token){
    config.headers={
    "access-token": store.state.access_token
  }
  }
  return config
},
(err=>{
  return Promise.reject(err)
}))
export default request