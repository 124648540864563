import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import { getCartList } from '../api'
export default createStore({
  state: {
    user_id: null,
    access_token: null,
    userdata: {},
    orderstuta: null,
    car: [],
    spi: false,
    carprice: 0,
    search_key: null,
    loading: false,
    address:{},
    cartpay:[],
    paydetail:{},
    allshow:null,//全部广告
  },
  getters: {
  },
  mutations: {
    setallshow(state, val) {
    state.allshow=val
    },
    getusermsg(state, val) {
      state.user_id = val.user_id
      state.access_token = val.access_token
    },
    getuserdata(state, val) {
      state.userdata = val.user
      // console.log('usermsg', state.userdata);
    },
    clear(state) {
      state.user_id = null
      state.access_token = null
      state.userdata = {}
    },
    setorderstuta(state, val) {
      state.orderstuta = val
    },
    setcard(state, val) {
      state.car = val
    },
    setspi(state, val) {
      state.spi = val
    },
    setcarprice(state, val) {
      let a = 0
      if (val.length != 0) {
        val.forEach(item => {
          a += item.cart_num * item.goods_spec_price
        });
        state.carprice = a.toFixed(2)
        state.loading = false
      } else {
        state.carprice = 0
        state.loading = false
      }
    },
    setsearchkey(state, val) {
      state.search_key = val
    },
    setloading(state) {
      state.loading = true
    },
    setaddress(state, val){
     state.address=val
    },
    setcartpay(state, val){
      state.cartpay=val
     },
     setpaydetail(state,val){
       state.paydetail=val
     }
  },
  actions: {
    async getcar({ state, commit }) {
      commit('setcarprice', [])
      commit('setspi', true)
      const { data: res } = await getCartList({ user_id: state.userdata.user_id })
      commit('setspi', false)
      let cart = res.cart.filter(item => item.goods_status == 1)
      commit('setcard', cart)
      commit('setcarprice', cart)
    },

  },
  modules: {
  },
  plugins: [
    createPersistedState()
  ]
})
